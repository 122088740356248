import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const TextInputWrapper = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DatePickerHeightPlaceholder = styled.span`
  display: block;
  min-height: 418px;
`;

export const StyledDatePickerWrapper = styled.span<{ theme: ThemeType }>`
  max-width: 400px;
  margin: 0 auto;

  .react-datepicker {
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    display: inline-block;

    position: absolute;
    width: 100%;
    left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
      position: relative;
    }
  }

  fieldset {
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }

  .react-datepicker-wrapper {
    display: inline-block;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #aeaeae;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f0f0f0;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
  }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
  }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff;
  }

  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #aeaeae;
  }

  .react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
  }
  .react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
  }
  .react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
  }
  .react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
  }
  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }
  .react-datepicker-popper {
    z-index: 1;
  }
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px;
  }
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px;
  }
  .react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px;
  }
  .react-datepicker-popper[data-placement^="right"]
    .react-datepicker__triangle {
    left: auto;
    right: 42px;
  }
  .react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px;
  }
  .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
    left: 42px;
    right: auto;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
  }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: ${({ theme }: { theme: ThemeType }) => theme.colors.textDark};
    font-family: ${({ theme }: { theme: ThemeType }) =>
      theme.typography.fontFamilyHeadings};
    font-size: ${({ theme }: { theme: ThemeType }) => theme.fontSizes.l};
  }
  .react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
  }

  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #333;
  }
  .react-datepicker__navigation--previous:hover {
    border-right-color: #555;
  }
  .react-datepicker__navigation--previous--disabled,
  .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
  }
  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #333;
  }
  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 80px;
  }
  .react-datepicker__navigation--next:hover {
    border-left-color: #555;
  }
  .react-datepicker__navigation--next--disabled,
  .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
  }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
  }
  .react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
  }
  .react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
  }
  .react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
  }
  .react-datepicker__month-container {
    float: left;
    width: 100%;
  }
  .react-datepicker__month {
    margin: 0;
    text-align: center;
  }
  .react-datepicker__month .react-datepicker__month-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }

  .react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
  }
  .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
  }
  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${({ theme }: { theme: ThemeType }) => theme.colors.textDark};
    display: inline-block;
    width: 14%;
    line-height: ${({ theme }: { theme: ThemeType }) => theme.spacing.m};
    text-align: center;
    margin: ${({ theme }: { theme: ThemeType }) => theme.spacing.xxs} 0;
  }

  .react-datepicker__day {
    border-width: 4px;
    border-style: solid;
    border-color: transparent;
  }

  .react-datepicker__day:focus,
  .react-datepicker__day:hover {
    border-color: ${({ theme }: { theme: ThemeType }) =>
      theme.name === "su2c" ? theme.colors.tertiary : theme.colors.secondary};
  }

  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range {
    border-radius: 0.3rem;
    background-color: #00007e;
    color: #fff;
  }
  .react-datepicker__month--selected:hover,
  .react-datepicker__month--in-selecting-range:hover,
  .react-datepicker__month--in-range:hover {
    background-color: #00007e;
  }
  .react-datepicker__month--disabled {
    color: #ccc;
    pointer-events: none;
  }
  .react-datepicker__month--disabled:hover {
    cursor: default;
    background-color: transparent;
  }
  .react-datepicker__day,
  .react-datepicker__month-text {
    cursor: pointer;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover {
    /* background-color: #f0f0f0; */
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today {
    font-weight: bold;
  }
  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
  }
  .react-datepicker__day--highlighted:hover,
  .react-datepicker__month-text--highlighted:hover {
    background-color: #32be3f;
  }

  .react-datepicker__day--highlighted-custom-1,
  .react-datepicker__month-text--highlighted-custom-1 {
    color: magenta;
  }
  .react-datepicker__day--highlighted-custom-2,
  .react-datepicker__month-text--highlighted-custom-2 {
    color: green;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: ${({ theme }: { theme: ThemeType }) =>
      theme.tokenColors.grey_200};
    color: ${({ theme }: { theme: ThemeType }) => theme.colors.textDark};
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover {
    background-color: ${({ theme }: { theme: ThemeType }) =>
      theme.colors.primary};
    color: ${({ theme }: { theme: ThemeType }) => theme.colors.textOnPrimary};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--in-selecting-range {
    background-color: ${({ theme }: { theme: ThemeType }) =>
      theme.tokenColors.grey_200};
    color: ${({ theme }: { theme: ThemeType }) => theme.colors.textDark};
  }

  .react-datepicker__day--selecting-range-start.react-datepicker__day--in-selecting-range {
    background-color: ${({ theme }: { theme: ThemeType }) =>
      theme.colors.primary};
    color: ${({ theme }: { theme: ThemeType }) => theme.colors.textOnPrimary};
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled {
    cursor: default;
    color: #ccc;
  }
  .react-datepicker__day--disabled:hover,
  .react-datepicker__month-text--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background-color: ${({ theme }: { theme: ThemeType }) =>
      theme.colors.primary};
    color: ${({ theme }: { theme: ThemeType }) => theme.colors.textOnPrimary};
  }

  .react-datepicker__month-text.react-datepicker__month--selected:hover,
  .react-datepicker__month-text.react-datepicker__month--in-range:hover {
    background-color: ${({ theme }: { theme: ThemeType }) =>
      theme.colors.primary};
    color: ${({ theme }: { theme: ThemeType }) => theme.colors.textOnPrimary};
  }
  .react-datepicker__month-text:hover {
    background-color: #f0f0f0;
  }
  .react-datepicker__input-container {
    position: relative;
    display: inline-block;

    input {
      font-size: ${({ theme }: { theme: ThemeType }) => theme.fontSizes.m};
      padding: ${({ theme }: { theme: ThemeType }) => theme.spacing.xxs};
      margin: ${({ theme }: { theme: ThemeType }) => `${theme.spacing.xs} 0`};

      width: 8em;
    }
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view,
  .react-datepicker__month-year-read-view {
    border: 1px solid transparent;
  }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer;
  }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: #b3b3b3;
    }
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
  }
  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
  }

  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
  }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
  .react-datepicker__year-option,
  .react-datepicker__month-option,
  .react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #ccc;
  }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #b3b3b3;
    }
  }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    .react-datepicker__navigation--years-previous {
      border-top-color: #b3b3b3;
    }
  }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
  }

  .react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;

    &:after {
      background-color: #00007e;
      border-radius: 50%;
      bottom: 0;
      box-sizing: border-box;
      color: #fff;
      content: "×";
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 1;
      margin: -8px auto 0;
      padding: 2px;
      position: absolute;
      right: 0px;
      text-align: center;
    }
  }
  /* screen reader only */
  .react-datepicker__aria-live {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;
